/** @format */
@mixin html {
	scroll-behavior: smooth;
}
// @include body; //
@mixin body {
	background-color: $white;
	overflow-x: hidden;
	color: #7f7f7f;
	font-family: $base-font;
	font-size: $font14;
	font-weight: $font-regular;
	margin: 0;
	padding: 0;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	outline: 0;
	overflow-y: overlay;
}

// @include pageHeading //
@mixin pageHeading {
	text-align: center;
	padding-bottom: $pad20;
	max-width: 890px;
	margin: 0 auto;
	h1 {
		background: #f5f5f5;
		font-size: $font22 + 4;
		font-family: $base-font-six;
		font-weight: $font-bold;
		color: $black;
		padding: 0;
		margin-bottom: $mrg10;
		text-transform: $uppercase;
	}
	p {
		color: $black;
	}
}

// @include img; //
@mixin img {
	max-width: 100%;
	height: auto;
	display: inline-block;
	vertical-align: middle;
	border: $bdr0;
	outline: $bdr0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

@mixin transition($all-transition) {
	transition: $all-transition;
	-webkit-transition: $all-transition;
	-moz-transition: $all-transition;
	-ms-transition: $all-transition;
}

@mixin scaleX($scale) {
	transform: scaleX($scale);
	-webkit-transform: scaleX($scale);
	-moz-transform: scaleX($scale);
}

@mixin scale($scale) {
	transform: scale($scale);
	-webkit-transform: scale($scale);
	-moz-transform: scale($scale);
}

@mixin translateY($value) {
	transform: translateY($value);
	-webkit-transform: translateY($value);
	-moz-transform: translateY($value);
}

@mixin translateX($value) {
	transform: translateX($value);
	-webkit-transform: translateX($value);
	-moz-transform: translateX($value);
}

@mixin translate($value) {
	transform: translate($value);
	-webkit-transform: translate($value);
	-moz-transform: translate($value);
}

@mixin rotate($rotate) {
	transform: rotate($rotate);
	-webkit-transform: rotate($rotate);
	-moz-transform: rotate($rotate);
}

@mixin grayscale($value) {
	-webkit-filter: grayscale($value);
	filter: grayscale($value);
}

@mixin shadow($left, $top, $radius, $color) {
	box-shadow: $left $top $radius $color;
	-webkit-box-shadow: $left $top $radius $color;
	-moz-box-shadow: $left $top $radius $color;
}

@mixin transform-none($value) {
	transform: $value;
	-webkit-transform: $value;
	-moz-transform: $value;
}

@mixin column-count($value) {
	column-count: $value;
	-webkit-column-count: $value;
}

@mixin flex-wrap($value) {
	flex-wrap: $value;
	-webkit-flex-wrap: $value;
}

@mixin animation-name($value) {
	animation-name: $value;
	-webkit-animation-name: $value;
	-moz-animation-name: $value;
	-o-animation-name: $value;
}

@mixin animation-duration($value) {
	animation-duration: $value;
	-webkit-animation-duration: $value;
}

@mixin animation-fill-mode($value) {
	animation-fill-mode: $value;
	-webkit-animation-fill-mode: $value;
}

@mixin animation-delay($value) {
	animation-delay: $value;
	-webkit-animation-delay: $value;
}

@mixin align-items($value) {
	align-items: $value;
	-webkit-align-items: $value;
}

@mixin gradient($direction, $gradient-color) {
	background: -webkit-linear-gradient($direction, $gradient-color);
	background: -moz-linear-gradient($direction, $gradient-color);
	background: -o-linear-gradient($direction, $gradient-color);
	background: linear-gradient($direction, $gradient-color);
}

// @include bdr-rds($radius: 4px); //
@mixin bdr-rds($radius: 0) {
	@if $radius !=0 {
		border-radius: $radius;
		-webkit-border-radius: $radius;
		-moz-border-radius: $radius;
	}
}

// @include form_control; //
@mixin form-control {
	padding: 0 $pad15;
	line-height: 50px;
	height: 50px;
	border: solid 1px $blackLight;
	@include bdr-rds($radius: 50px);
	font-family: $base-font;
	font-size: $font14;
	font-weight: $font-regular;
	&:focus {
		outline: none;
		box-shadow: none;
		border-color: $blackLight;
	}
}

@mixin form-group {
}

// @include select_box; //
@mixin select_box {
	background: $white url('#{$image-path}/arrowdown.png') no-repeat
		calc(100% - 15px) center;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 28px;
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	appearance: none;
	color: #495057;
	border-color: $blackLight;
	padding-left: $pad15;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}

@mixin boxOverley {
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9;
	display: none;
}
@mixin disableBtn {
	&:disabled {
		background-color: $grey;
		cursor: no-drop;
		&:hover {
			background-color: $grey;
		}
	}
}

@mixin btnCustom {
	border: none;
	background: $peach;
	line-height: 42px;
	height: 42px;
	text-align: center;
	display: $d-block;
	cursor: pointer;
	font-size: $font16;
	color: $white;
	font-family: $base-font;
	font-weight: $font-semibold;
	@include bdr-rds($radius: 50px);
	width: 100%;
	@include transition(all 0.3s ease);
	position: $relative;
	overflow: hidden;
	&:hover {
		background: rgba($peach, 0.85);
	}
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		width: 100%;
		height: 70px;
		background: rgba(255, 255, 255, 0.2);
		@include bdr-rds($radius: 100px);
		transform: scale(0.1);
		opacity: 0;
		@include transition(all 0.3s ease);
		margin: -35px auto 0;
	}
	&:focus {
		outline: none;
		&:after {
			opacity: 1;
			transform: scale(1);
		}
	}
	&.black-bt {
		background: $black;
	}
}
