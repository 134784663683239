/** @format */
@import '@styles/variables';
@import '@styles/mixin';

.btnCustom {
	@include btnCustom;
}

.confirmationPopup {
	display: block;
	.confirmationBody {
		.icon {
			text-align: center;
			.error {
				color: $red;
			}
			.warning {
				color: $orange;
			}
			.success {
				color: $green;
			}
			i {
				font-size: 40px;
			}
		}
		.btnCustom {
			height: 35px;
			line-height: 35px;
			border-radius: 10px;
		}
		p {
			text-align: center;
			color: $black;
			font-size: $font16;
		}
		h5 {
			margin-top: $mrg20;
			text-align: center;
			font-weight: $font-regular;
			margin-bottom: $mrg10;
		}
		.formGroup {
			position: $relative;
			display: flex;
			margin-bottom: $mrg0;
			.btnCustom {
				width: 38%;
				margin: 0 6%;
			}
		}
	}
}
