/**
 * /* color variable
 *
 * @format
 */

$orange: #f04f24;
$orange-two: #ffe4e5;
$light-orange: #fac7c9;
$white: #fff;
$white-two: #f5f5f5;
$black: #000;
$blackLight: #11141c;
$grey: #a8a2a2;
$grey-two: #ddd;
$darkGrey: #787878;
$red: #ff0000;
$red-one: #e30024;
$green: #24be00;
$darkPink: #e5a6a3;
$sky: #5897fb;
$skylight: #8bb8bb;
$greenDark: #496240;
$lightBrown: #e5d8c9;
$blackNew: #12141a;
$lightPink: #efaaa7;
$peach: #fab5b2;
$moss: #405738;
$pistachio: #f0e3d4;
$overcast: #99c8cc;
$black-CP: #12141a;

/* font variable */
$base-font: 'Poppins', sans-serif;
$base-font-two: 'Montserrat', sans-serif;
$base-font-three: 'proxima_novasemibold';
$base-font-four: 'proxima_novabold';
$base-font-five: 'proxima_novaregular';
$base-font-six: 'Poppins-Bold', sans-serif;
$base-font-seven: 'Barlow';
$base-font-eight: 'Barlow_Bold';
$default-font: sans-serif;
$font-awesome: 'Font Awesome 5 Free';
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$uppercase: uppercase;

$text-left: left;
$text-center: center;
$text-right: right;

$pointer: pointer;
$d-block: block;
$inline-block: inline-block;
$d-none: none;
$none: none;

$relative: relative;
$absolute: absolute;
$fixed: fixed;
$sticky: sticky;

//border
$bdr0: none;
$bdr1: 1px;
$bdr2: 2px;
$bdr3: 3px;
$bdr4: 4px;

//padding
$pad0: 0 !important;
$pad2: 2px;
$pad5: 5px;
$pad7: 7px;
$pad10: 10px;
$pad15: 15px;
$pad20: 20px;
$pad25: 25px;
$pad30: 30px;
$pad35: 35px;
$pad40: 40px;
$pad45: 45px;
$pad50: 50px;
$pad55: 55px;
$pad60: 60px;

//margin
$mrgauto: auto;
$mrg0: 0 !important;
$mrg5: 5px;
$mrg7: 7px;
$mrg10: 10px;
$mrg15: 15px;
$mrg20: 20px;
$mrg25: 25px;
$mrg30: 30px;
$mrg35: 35px;
$mrg40: 40px;
$mrg45: 45px;
$mrg50: 50px;
$mrg55: 55px;
$mrg60: 60px;

//rem px Val
$_0px: 0 !important;
$_3px: 0.1875rem;
$_5px: 0.3125rem;
$_7px: 0.4375rem;
$_10px: 0.625rem;
$_15px: 0.9375rem;
$_20px: 1.25rem;
$_25px: 1.5625rem;
$_30px: 1.875rem;
$_35px: 2.1875rem;
$_40px: 2.5rem;
$_45px: 2.8125rem;
$_50px: 3.125rem;
$_60px: 3.75rem;

//font
$font12: 12px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 22px;
$font26: 26px;
$font30: 30px;
$font55: 55px;

//heading
$headingOne: 38px;
$headingTwo: 34px;
$headingThree: 30px;
$headingFour: 26px;
$headingFive: 22px;
$headingSix: 18px;

$custom_exs: 385px;
$custom_xs: 500px;
$custom_xss: 575px;
$custom_xsm: 600px;
$custom_sm: 767px;
$custom_md: 991px;
$custom_xmd: 1034px;
$custom_xl: 1199px;
$custom_xsl: 1440px;
$custom_xxl: 1600px;

$image-path: '/assets/images';
$webfonts-path: '/assets/webfonts';
